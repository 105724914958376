import { Form, Input, Typography } from 'antd';
import React from 'react'
import { Controller } from 'react-hook-form'

interface IHookFormInputField extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    control: any;
    label?: any;
    callback?: Function;
    formGroupName?: any;
    addonAfter?: string;
    addonBefore?: string;
}
function HookFormInputField({ name, control, label, required, className, formGroupName, callback, addonAfter,addonBefore, ...props }: IHookFormInputField) {
    return (
        <>
        <Controller
            name={name}
            control={control}
            render={({
                field,
                fieldState: { error },
            }) => (
                <Form.Item
                    label={required && label ?
                        <>
                            <Typography.Text>{label}</Typography.Text> <Typography.Text type='danger' className='ml-1'>*</Typography.Text>
                        </> :
                        label}
                    className={`${formGroupName ? formGroupName : ""}`}
                    help={error?.message}
                    validateStatus={error ? 'error' : ''}>

                    {
                        props.type === "password" ?
                            <Input.Password {...props as any} {...field} />
                            :
                            
                            <Input {...props as any} {...field} onChange={(selectedValue) => {
                                field.onChange(selectedValue);
                                if (callback) {
                                    callback(selectedValue);
                                }
                            }}
                            addonBefore={addonBefore ? addonBefore : null}
                            addonAfter={addonAfter ? addonAfter : null}/>
                    }
                </Form.Item>
            )}
        />
        <style>
            {`
            // .ant-col .ant-form-item-label .css-dev-only-do-not-override-af4yj3{
            // flex: 0 0 100%;
            // max-width: 100%;
            // }
            // :where(.css-dev-only-do-not-override-af4yj3).ant-form-item .ant-form-item-label{
            // text-align: left!important;
            // }
        //    :where(.css-dev-only-do-not-override-af4yj3).ant-row{
        //     display: block!important;
        //     } 
            `
            }
        </style>
</>
    )
}

export default HookFormInputField